<template>
  <v-lazy>
    <v-card>
      <v-card-title></v-card-title>
        <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
          <div data-v-6fcca708="" class="me-2 mb-2">
            <div style="display: flex">
              <span>
                <div data-v-6fcca708="" class="v-card__title">
                  {{ translate("Search Products") }}
                </div>
              </span>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search by SKU" single-line
                hide-details></v-text-field>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="20" class="table-rounded"
          hide-default-footer>
          <template v-slot:item.image="{ item }">
            <v-img height="75" width="75" :src="item.image_url"></v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" @click="selectProduct(item)">{{
              translate("SELECT")
            }}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-lazy>
</template>
<script>
import axios from "axios";
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  newClient,
  searchForClients,
} from "@/ApiManager";

export default {
  props: ["items"],
  data() {
    return {
      search: null,
      emptyRule: [(v) => !!v || "Can't be empty!"],

      headers: [
        { text: this.translate("Image"), value: "image" },

        { text: this.translate("Name"), value: "product_name" },
        { text: this.translate("SKU"), value: "sku" },
        { text: this.translate("Sell Price"), value: "sell_price" },
        { text: this.translate("Actions"), value: "actions" },
      ],
    };
  },
  methods: {
    selectProduct(product) {
      this.$emit("closeDialog", product);
    },
    closeDialog() {
      this.$emit("closeDialog", null);
    },
  },
  created() { },
};
</script>
